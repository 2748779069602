import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faTimes, faUndoAlt } from '@fortawesome/pro-regular-svg-icons';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtBusinessGroup, RtFleetName, RtRouteCode, RtWhoPays } from '@bds/railtrac-models';
import { RtCustomerFact } from '@bds/reference-models';
import { RtDiversionFormModel } from '../models/rt-diversion-form.model';
import { RtDiversionDetail } from '../models/rt-diversion-detail.model';
import { RtDiversionAdapterService } from '../services/rt-diversion-adapter.service';
import { RtDiversionFormService } from '../services/rt-diversion-form.service';
import { CustomValidators } from '../services/rt-diversion.validators';

@Component({
    selector: 'rt-diversion-details',
    templateUrl: './rt-diversion-details.component.html',
    styleUrls: ['./rt-diversion-details.component.scss'],
})
export class RtDiversionDetailsComponent implements OnChanges, OnInit {
    activeFilter: string[] = ['activeStatus', '<>', 'N'];
    currentDate: Date = new Date();
    custFilter: any[] = null;
    defaultCustomerType: string = '';
    diversionForm: UntypedFormGroup;
    divertType: string;
    filteredDiversionTypes: { key: string; value: string }[] = [];
    iconRemove = faTimes;
    iconUndo = faUndoAlt;
    lastShipDate: Date;
    popupDim = { minWidth: '250px' };
    routeSearchExpr: string[] = ['routeCode', 'routeDescription'];
    routeSource: DataSource;
    splcDisplayExpr: string[] = ['erpcCity', 'erpcState', 'splc'];
    splcSource: DataSource;

    @Input() businessGroups: RtBusinessGroup[];
    @Input() diversion: RtDiversionFormModel;
    @Input() diversionTypes: { key: string; value: string }[];
    @Input() fleetNames: RtFleetName[];
    @Input() isSaving: boolean = false;
    @Input() routeStore: ODataStore;
    @Input() splcStore: ODataStore;
    @Input() whoPays: RtWhoPays[];
    @Output() cancel: EventEmitter<void> = new EventEmitter();
    @Output() save: EventEmitter<RtDiversionFormModel> = new EventEmitter();

    routeDisplay = function (data: RtRouteCode) {
        return data ? `${data.routeCode}` : '';
    };

    constructor(
        private diversionAdapter: RtDiversionAdapterService,
        private diversionFormService: RtDiversionFormService,
        private formErrorService: FormErrorHandlerService,
    ) {
        this.diversionForm = diversionFormService.createForm();
        this.currentDate.setHours(0, 0, 0, 0);
    }

    ngOnInit() {
        // TODO: Only run if this is empty
        this.setUpSplcOptions();
        this.setUpRouteOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.diversionTypes && this.diversionTypes) {
            if (this.diversion) {
                this.filterDivertOptions(this.diversion.roads[0].diversions[0]);
            } else {
                this.filteredDiversionTypes = this.diversionTypes;
            }
        }

        if (changes.diversion && this.diversion) {
            this.resetForm();
            this.diversionForm.patchValue(this.diversion);

            if (this.diversionTypes && this.diversionTypes.length) {
                this.filterDivertOptions(this.diversion.roads[0].diversions[0]);
            }

            const roadControl: UntypedFormArray = this.diversionForm.controls
                .roads as UntypedFormArray;
            this.diversion.roads.forEach((r) => {
                const roadForm: UntypedFormGroup = this.diversionFormService.createRoad();
                roadForm.patchValue(r);

                const diversionControl: UntypedFormArray = roadForm.controls
                    .diversions as UntypedFormArray;
                r.diversions.forEach((t) => {
                    const diversionForm: UntypedFormGroup =
                        this.diversionFormService.createDiversion();
                    diversionForm.patchValue(t);
                    diversionControl.push(diversionForm);
                });

                roadControl.push(roadForm);
            });

            this.calculateMinDivertDate();
        }
    }

    calculateMinDivertDate(): void {
        const emptyDiversion = {} as RtDiversionDetail;
        const data = this.diversionForm.value as RtDiversionFormModel;

        this.lastShipDate = this.diversionAdapter
            .flattenDiversions(data.roads)
            .reduce(
                (prev, current) => (prev.shipDateTime > current.shipDateTime ? prev : current),
                emptyDiversion,
            ).shipDateTime;

        const divertControl = this.getHeader().get('divertDateTime');

        if (
            data.header.divertDateTime &&
            data.header.divertDateTime < new Date(this.lastShipDate)
        ) {
            divertControl.setValue(null);
        }

        divertControl.setValidators([
            Validators.required,
            CustomValidators.dateMinimum(this.lastShipDate),
        ]);
        divertControl.updateValueAndValidity();
    }

    filterDivertOptions(trip: RtDiversionDetail): void {
        this.diversion.header.divertType = trip.carStatus === '1' ? 'L' : 'O';
        this.divertType = this.diversion.header.divertType;
        this.filteredDiversionTypes = this.diversionTypes;
        if (trip.carStatus === '1' && trip.clmLe === 'L') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) => d.key === 'L' || d.key === 'Q',
            );
        } else if (trip.carStatus === '3' && trip.clmLe === 'L') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) => d.key === 'O' || d.key === 'Q',
            );
        } else if (trip.carStatus === '1' && trip.clmLe === 'E') {
            this.filteredDiversionTypes = this.diversionTypes.filter((d) => d.key !== 'O');
            this.diversion.header.divertType = 'D';
        } else if (trip.carStatus === '3' && trip.clmLe === 'E') {
            this.filteredDiversionTypes = this.diversionTypes.filter((d) => d.key !== 'L');
        }

        // Set the dropdown
        this.setControlsPreDivertTypeChange(null, this.divertType);
        this.getHeader().get('divertType').setValue(this.divertType);
        this.setControlsPostDivertTypeChange(null, this.divertType);
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        if (formItem.hasError('date-minimum')) {
            return `Must be >= ${formItem.errors['date-minimum']['date-minimum']}`;
        } else {
            return this.formErrorService.getFormError(formItem);
        }
    }

    getHeader(): UntypedFormGroup {
        return this.diversionForm.get('header') as UntypedFormGroup;
    }

    getRoadByIndex(i: number): UntypedFormGroup {
        return this.getRoads().controls[i] as UntypedFormGroup;
    }

    getRoads(): UntypedFormArray {
        return this.diversionForm.get('roads') as UntypedFormArray;
    }

    getDiversionByIndex(iRoad: number, iDiversion: number): UntypedFormGroup {
        const diversions: UntypedFormArray = this.getDiversions(iRoad) as UntypedFormArray;
        return diversions.controls[iDiversion] as UntypedFormGroup;
    }

    getDiversions(i: number): UntypedFormArray {
        const roadList: UntypedFormArray = this.diversionForm.get('roads') as UntypedFormArray;
        return roadList.controls[i].get('diversions') as UntypedFormArray;
    }

    onCancel(): void {
        this.cancel.emit();
    }

    onCalendarOpened(event): void {
        const divertDate = this.getHeader().get('divertDateTime').value;

        if (!divertDate) {
            // Set date to midnight of current day
            setTimeout(() => {
                event.component._strategy._timeView.option('value', this.currentDate);
                event.component._strategy._widget.option('value', this.currentDate);
            });
        }
    }

    onCustomerChanged(event: RtCustomerFact): void {
        const headerControls: UntypedFormGroup = this.getHeader();
        if (event) {
            headerControls.get('newCustNo').setValue(event.customerNo);
            headerControls.get('newCustName').setValue(event.customerName);
            headerControls.get('newCustCity').setValue(event.customerCity);
            headerControls.get('newCustState').setValue(event.customerState);
        } else {
            headerControls.get('newCustNo').setValue(null);
            headerControls.get('newCustName').setValue(null);
            headerControls.get('newCustCity').setValue(null);
            headerControls.get('newCustState').setValue(null);
        }
    }

    onDivertDatePasted(event): void {
        const pastedItem = event.event.originalEvent.clipboardData.items[0];
        pastedItem.getAsString(function (data) {
            event.component.option('value', new Date(data));
        });
    }

    onDivertTypeChange(event): void {
        this.getHeader().get('newCustNo').markAsDirty();

        if (event && event.value) {
            const oldDivertType = this.divertType;
            // First remove all validators and values that cannot exist
            this.setControlsPreDivertTypeChange(oldDivertType, event.value);
            // Now change divertType and reset validators, values, and filters as needed
            this.divertType = event.value;
            this.setControlsPostDivertTypeChange(oldDivertType, event.value);
        }
    }

    onRemoveClick(diversion: UntypedFormGroup, roadId): void {
        const newDivertValue = !diversion.controls['divert'].value;
        diversion.controls['divert'].setValue(newDivertValue);

        if (!newDivertValue) {
            (diversion.controls['businessGroup'] as UntypedFormControl).disable();
            (diversion.controls['fleetId'] as UntypedFormControl).disable();
            (diversion.controls['routeDescription'] as UntypedFormControl).disable();

            if (this.divertType === 'S' || this.divertType === 'Q') {
                (diversion.controls['returnSplc'] as UntypedFormControl).disable();
            } else if (this.divertType === 'L') {
                (diversion.controls['orderNo'] as UntypedFormControl).disable();
                (diversion.controls['bolNo'] as UntypedFormControl).disable();
                (diversion.controls['routeCode'] as UntypedFormControl).disable();
            }
        } else {
            (diversion.controls['businessGroup'] as UntypedFormControl).enable();
            (diversion.controls['fleetId'] as UntypedFormControl).enable();
            (diversion.controls['routeDescription'] as UntypedFormControl).enable();

            if (this.divertType === 'S' || this.divertType === 'Q') {
                (diversion.controls['returnSplc'] as UntypedFormControl).enable();
            } else if (this.divertType === 'L') {
                (diversion.controls['orderNo'] as UntypedFormControl).enable();
                (diversion.controls['bolNo'] as UntypedFormControl).enable();
                (diversion.controls['routeCode'] as UntypedFormControl).enable();
            }
        }

        this.calculateMinDivertDate();
    }

    onRoadChanged(event, road, type: string): void {
        const newValue: string = event.target.value;
        const diversionControls: UntypedFormGroup[] = road.controls.diversions.controls;

        diversionControls.forEach((c) =>
            (c.controls[type] as UntypedFormControl).setValue(newValue),
        );
    }

    onRoadDropdownChanged(event, road, type: string): void {
        const newValue: string = event.selectedItem[type];
        const diversionControls: UntypedFormGroup[] = road.controls.diversions.controls;

        diversionControls.forEach((c) =>
            (c.controls[type] as UntypedFormControl).setValue(newValue),
        );
    }

    onRouteChanged(event, type: string, ctrl): void {
        if (event) {
            const routeDescription = event.routeDescription || event.routeDscr;
            if (type === 'road' && ctrl) {
                (ctrl.controls['routeDescription'] as UntypedFormControl).setValue(
                    routeDescription,
                );
                const diversionControls: UntypedFormGroup[] = ctrl.controls.diversions.controls;

                diversionControls.forEach((c) => {
                    (c.controls['routeDescription'] as UntypedFormControl).setValue(
                        routeDescription,
                    );
                    const rc = c.controls['routeCode'] as UntypedFormControl;
                    rc.setValue(event.routeCode);
                    rc.markAsTouched();
                });
            } else if (type === 'diversion' && ctrl) {
                (ctrl.controls['routeDescription'] as UntypedFormControl).setValue(
                    routeDescription,
                );
                const rc = ctrl.controls['routeCode'] as UntypedFormControl;
                rc.setValue(event.routeCode);
                rc.markAsTouched();
            }
        }
    }

    onSplcChanged(event, type: string, ctrl = null): void {
        if (event && event.selectedItem) {
            if (type === 'header') {
                const headerControls: UntypedFormGroup = this.getHeader();

                headerControls.get('newReturnCity').setValue(event.selectedItem.erpcCity);
                headerControls.get('newReturnState').setValue(event.selectedItem.erpcState);
            } else if (type === 'road' && ctrl) {
                const diversionControls: UntypedFormGroup[] = ctrl.controls.diversions.controls;

                diversionControls.forEach((c) => {
                    const splc = c.controls['returnSplc'] as UntypedFormControl;
                    splc.setValue(event.selectedItem.splc);
                    splc.markAsTouched();
                });
            }
        }
    }

    onSubmit(): void {
        this.save.emit(this.diversionForm.value);
    }

    resetForm(): void {
        this.getRoads().clear();
        this.diversionForm.reset();
    }

    setUpRouteOptions(): void {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.routeSource = new DataSource({
            store: this.routeStore,
            paginate: true,
            pageSize: 10,
            searchExpr: this.routeSearchExpr,
            select: ['routeCode', 'routeDescription'],
            sort: ['routeCode', 'routeDescription'],
            key: 'routeCode',
        });
    }

    setUpSplcOptions(): void {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.splcSource = new DataSource({
            store: this.splcStore,
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['erpcCity', 'erpcState', 'splc'],
            sort: ['erpcCity', 'erpcState'],
            key: 'splc',
        });
    }

    setControlsPostDivertTypeChange(oldDivertValue: string, newDivertValue: string): void {
        const headerControls: UntypedFormGroup = this.getHeader();

        if ((oldDivertValue === 'O' || !oldDivertValue) && newDivertValue !== 'O') {
            // Make sure anything in the Customer inputs is cleared
            headerControls?.get('newCustCity').setValue(null);
            headerControls?.get('newCustState').setValue(null);
            // Now make this a required field
            headerControls?.get('newCustNo').setValidators([Validators.required]);
            headerControls?.get('newCustNo').updateValueAndValidity();
        } else if (newDivertValue === 'O') {
            headerControls?.get('newReturnSplc').setValidators([Validators.required]);
            headerControls?.get('newReturnSplc').updateValueAndValidity();
        }

        if (newDivertValue === 'S' || newDivertValue === 'Q') {
            this.defaultCustomerType = newDivertValue;
            this.custFilter = this.activeFilter;

            const roads = (this.diversionForm.get('roads') as UntypedFormArray)
                .controls as UntypedFormGroup[];
            roads.forEach((g) => {
                const roadSplc = g.get('returnSplc');
                if (roadSplc) {
                    const diversions = (g.get('diversions') as UntypedFormArray)
                        .controls as UntypedFormGroup[];
                    diversions.forEach((d) => {
                        const routeDesc = d.get('routeDescription');
                        const routeCode = d.get('routeCode');
                        if (newDivertValue === 'S' && routeDesc) {
                            routeDesc.setValue('DIVERT TO SHOP');
                        }

                        if (newDivertValue === 'Q' && routeDesc) {
                            routeDesc.setValue('DIVERT TO STORAGE');
                            routeCode.setValue('DIVERT TO STORAGE');
                        }
                        const splc = d.get('returnSplc');
                        if (splc) {
                            splc.setValidators([Validators.required]);
                            splc.updateValueAndValidity();
                        }
                    });
                }
            });
        } else {
            this.defaultCustomerType = null;
            // Only take those not specifially listed as inactive
            this.custFilter = this.activeFilter;
        }
    }

    setControlsPreDivertTypeChange(oldDivertValue, newDivertValue): void {
        const headerControls: UntypedFormGroup = this.getHeader();
        const returnSplcCtrl = headerControls.get('newReturnSplc');
        const custNoCtrl = headerControls.get('newCustNo');

        if (oldDivertValue !== 'O' && newDivertValue !== 'O' && custNoCtrl) {
            custNoCtrl.setValue(null);
            headerControls.get('newCustCity').setValue(null);
            headerControls.get('newCustState').setValue(null);
        } else if (newDivertValue !== 'O' && returnSplcCtrl) {
            returnSplcCtrl.clearValidators();
            returnSplcCtrl.updateValueAndValidity();
            returnSplcCtrl.setValue(null);
        } else if (newDivertValue === 'O' && custNoCtrl) {
            custNoCtrl.clearValidators();
            custNoCtrl.updateValueAndValidity();
            custNoCtrl.setValue(null);
        }

        const roads = (this.diversionForm.get('roads') as UntypedFormArray)
            .controls as UntypedFormGroup[];
        roads.forEach((g) => {
            const roadSplc = g.get('returnSplc');
            if (roadSplc) {
                roadSplc.setValue(null);
                const diversions = (g.get('diversions') as UntypedFormArray)
                    .controls as UntypedFormGroup[];
                diversions.forEach((d) => {
                    const routeDesc = d.get('routeDescription');
                    if (routeDesc) {
                        routeDesc.setValue(null);
                    }
                    const splc = d.get('returnSplc');
                    if (splc) {
                        splc.clearValidators();
                        splc.updateValueAndValidity();
                        splc.setValue(null);
                    }
                });
            }
        });
    }
}
