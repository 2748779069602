import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DevExtremeModule, DxDataGridModule, DxDateBoxModule } from 'devextreme-angular';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsHelpersModule } from '@bds/helpers';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsSmartComponentsModule } from '@bds/smart-components';

import { RailtracReportsComponent } from './railtrac-reports.component';
import { RtShipmentCarSituationAllReportComponent } from './shipment/rt-shipment-car-situation-all-report/rt-shipment-car-situation-all-report.component';
import { RtSmartReportComponent } from './smart-components/rt-smart-report/rt-smart-report.component';

import { CsSummaryByFleetBusinessGroupComponent } from './car-status-summary/cs-summary-by-fleet-business-group/cs-summary-by-fleet-business-group.component';
import { CsSummaryByFleetCommClassComponent } from './car-status-summary/cs-summary-by-fleet-comm-class/cs-summary-by-fleet-comm-class.component';
import { CsSummaryCurrentDestinationComponent } from './car-status-summary/cs-summary-current-destination/cs-summary-current-destination.component';
import { CsSummaryCurrentOriginComponent } from './car-status-summary/cs-summary-current-origin/cs-summary-current-origin.component';
import { CsSummaryHistoryComponent } from './car-status-summary/cs-summary-history/cs-summary-history.component';
import { CsSummaryOriginHistoryComponent } from './car-status-summary/cs-summary-origin-history/cs-summary-origin-history.component';

import { RtShipmentCarSituationCurrentReportComponent } from './shipment/rt-shipment-car-situation-current-report/rt-shipment-car-situation-current-report.component';
import { RtShipmentCarSituationCurrentExtendedReportComponent } from './shipment/rt-shipment-car-situation-current-extended-report/rt-shipment-car-situation-current-extended-report.component';
import { RtTransitTimeCycleCustomerSummaryReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-customer-summary-report/rt-transit-time-cycle-customer-summary-report.component';
import { RtTransitTimeCycleCustomerSummaryAllReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-customer-summary-all-report/rt-transit-time-cycle-customer-summary-all-report.component';
import { RtTransitTimeCycleDetailReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-detail-report/rt-transit-time-cycle-detail-report.component';
import { RtTransitTimeCycleDetailAllReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-detail-all-report/rt-transit-time-cycle-detail-all-report.component';
import { RtTransitTimeCycleSummaryReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-summary-report/rt-transit-time-cycle-summary-report.component';
import { RtTransitTimeCycleSummaryAllReportComponent } from './transit-time-by-cycle/rt-transit-time-cycle-summary-all-report/rt-transit-time-cycle-summary-all-report.component';
import { RtTransitTimeDayOfWeekComponent } from './transmit-time/rt-transit-time-day-of-week/rt-transit-time-day-of-week.component';
import { RtTransitTimeDestinationStateComponent } from './transmit-time/rt-transit-time-destination-state/rt-transit-time-destination-state.component';
import { RtTransitTimeRsDetailComponent } from './transit-time-by-route-segment/rt-transit-time-rs-detail/rt-transit-time-rs-detail.component';
import { RtTransitTimeRsSummaryComponent } from './transit-time-by-route-segment/rt-transit-time-rs-summary/rt-transit-time-rs-summary.component';
import { RtTransitTimeCtaBusinessComponent } from './transit-time-by-car-turn/rt-transit-time-cta-business/rt-transit-time-cta-business.component';
import { RtTransitTimeCtaFleetComponent } from './transit-time-by-car-turn/rt-transit-time-cta-fleet/rt-transit-time-cta-fleet.component';
import { RtTransitTimeCtaFleetBusinessComponent } from './transit-time-by-car-turn/rt-transit-time-cta-fleet-business/rt-transit-time-cta-fleet-business.component';
import { TransitTimeTripCycleDetailComponent } from './transit-time-trip-cycle/transit-time-trip-cycle-detail/transit-time-trip-cycle-detail.component';
import { TransitTimeTripCycleSummaryComponent } from './transit-time-trip-cycle/transit-time-trip-cycle-summary/transit-time-trip-cycle-summary.component';
import { RtReportParametersComponent } from './smart-components/rt-report-parameters/rt-report-parameters.component';
import { RtCompletedShipmentsAllComponent } from './completed-shipment/rt-completed-shipments-all/rt-completed-shipments-all.component';
import { RtCompletedShipmentsNoDiversionsComponent } from './completed-shipment/rt-completed-shipments-no-diversions/rt-completed-shipments-no-diversions.component';
import { RtOutboundByCustomerReportComponent } from './outbound-at-customer/rt-outbound-by-customer-report/rt-outbound-by-customer-report.component';
import { RtOutboundByCustomerCsrReportComponent } from './outbound-at-customer/rt-outbound-by-customer-csr-report/rt-outbound-by-customer-csr-report.component';
import { RtOutboundByCustomerProductReportComponent } from './outbound-at-customer/rt-outbound-by-customer-product-report/rt-outbound-by-customer-product-report.component';
import { RtOutboundByCustomerProductVolumeReportComponent } from './outbound-at-customer/rt-outbound-by-customer-product-volume-report/rt-outbound-by-customer-product-volume-report.component';
import { RtOutboundByFleetReportComponent } from './outbound-at-customer/rt-outbound-by-fleet-report/rt-outbound-by-fleet-report.component';
import { RtDayInToOriginReportComponent } from './days/rt-day-in-to-origin-report/rt-day-in-to-origin-report.component';
import { RtDayOutToConsigneeReportComponent } from './days/rt-day-out-to-consignee-report/rt-day-out-to-consignee-report.component';
import { RtShipmentHistoryDetailReportComponent } from './shipment-history/rt-shipment-history-detail-report/rt-shipment-history-detail-report.component';
import { RtShipmentHistorySummaryReportComponent } from './shipment-history/rt-shipment-history-summary-report/rt-shipment-history-summary-report.component';
import { RtConsigneeCarRetDetailComponent } from './days/rt-consignee-car-ret-detail/rt-consignee-car-ret-detail.component';
import { RtConsigneeCarRetSummaryComponent } from './days/rt-consignee-car-ret-summary/rt-consignee-car-ret-summary.component';
import { EtaPerformanceRouteComponent } from './speciality/eta-performance-route/eta-performance-route.component';
import { EtaPerformanceShipmentComponent } from './speciality/eta-performance-shipment/eta-performance-shipment.component';
import { OriginPerformanceComponent } from './speciality/origin-performance/origin-performance.component';
import { DeliveryPerfConsigneeComponent } from './speciality/delivery-perf-consignee/delivery-perf-consignee.component';
import { DeliveryPerfFleetBusinessGrpComponent } from './speciality/delivery-perf-fleet-business-grp/delivery-perf-fleet-business-grp.component';

import { BadOrderHistoryComponent } from './reports/bad-order-history/bad-order-history.component';
import { CarWithNoOutageComponent } from './reports/car-with-no-outage/car-with-no-outage.component';
import { OffLeaseReportComponent } from './reports/off-lease-report/off-lease-report.component';
import { OperationalMetricsComponent } from './reports/operational-metrics/operational-metrics.component';
import { TripCommentsReportComponent } from './reports/trip-comments-report/trip-comments-report.component';

import { CalculateTotalDays } from './pipes/calculateTotalDays.pipe';
import { CalculateVariance } from './pipes/calculateVariance.pipe';
import { CalculateCLMAge } from './pipes/calculateCLMAge.pipe';
import { GetCountByStatus } from './pipes/getCountByStatus.pipe';
import { GetTotalCount } from './pipes/getTotalCount.pipe';

@NgModule({
    declarations: [
        RailtracReportsComponent,
        RtShipmentCarSituationAllReportComponent,
        RtSmartReportComponent,
        RtShipmentCarSituationCurrentReportComponent,
        RtShipmentCarSituationCurrentExtendedReportComponent,
        RtTransitTimeCycleCustomerSummaryReportComponent,
        RtTransitTimeCycleCustomerSummaryAllReportComponent,
        RtTransitTimeCycleDetailReportComponent,
        RtTransitTimeCycleDetailAllReportComponent,
        RtTransitTimeCycleSummaryReportComponent,
        RtTransitTimeCycleSummaryAllReportComponent,
        RtTransitTimeDestinationStateComponent,
        RtTransitTimeRsDetailComponent,
        RtTransitTimeDayOfWeekComponent,
        RtTransitTimeRsSummaryComponent,
        RtTransitTimeCtaBusinessComponent,
        RtTransitTimeCtaFleetComponent,
        RtTransitTimeCtaFleetBusinessComponent,
        TransitTimeTripCycleDetailComponent,
        TransitTimeTripCycleSummaryComponent,
        RtReportParametersComponent,
        RtCompletedShipmentsAllComponent,
        RtCompletedShipmentsNoDiversionsComponent,
        RtOutboundByCustomerReportComponent,
        RtOutboundByCustomerCsrReportComponent,
        RtOutboundByCustomerProductReportComponent,
        RtOutboundByCustomerProductVolumeReportComponent,
        RtOutboundByFleetReportComponent,
        RtConsigneeCarRetDetailComponent,
        RtConsigneeCarRetSummaryComponent,
        RtDayInToOriginReportComponent,
        RtDayOutToConsigneeReportComponent,
        RtShipmentHistoryDetailReportComponent,
        RtShipmentHistorySummaryReportComponent,
        EtaPerformanceRouteComponent,
        EtaPerformanceShipmentComponent,
        OriginPerformanceComponent,
        BadOrderHistoryComponent,
        CalculateTotalDays,
        CalculateVariance,
        CalculateCLMAge,
        CsSummaryByFleetBusinessGroupComponent,
        GetCountByStatus,
        GetTotalCount,
        CsSummaryByFleetCommClassComponent,
        CsSummaryCurrentDestinationComponent,
        CsSummaryCurrentOriginComponent,
        CsSummaryHistoryComponent,
        CsSummaryOriginHistoryComponent,
        DeliveryPerfConsigneeComponent,
        DeliveryPerfFleetBusinessGrpComponent,
        CarWithNoOutageComponent,
        OffLeaseReportComponent,
        OperationalMetricsComponent,
        TripCommentsReportComponent,
    ],
    imports: [
        BourqueCoreModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        CommonModule,
        DxDataGridModule,
        DxDateBoxModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatTableModule,
        MatSelectModule,
        MatChipsModule,
        MatListModule,
        MatSidenavModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatTabsModule,
        BdsHelpersModule,
        DevExtremeModule,
        BdsSmartComponentsModule,
    ],
    exports: [
        RailtracReportsComponent,
        RtShipmentCarSituationAllReportComponent,
        RtShipmentCarSituationCurrentReportComponent,
        RtShipmentCarSituationCurrentExtendedReportComponent,
        RtTransitTimeCycleCustomerSummaryReportComponent,
        RtTransitTimeCycleCustomerSummaryAllReportComponent,
        RtTransitTimeCycleDetailReportComponent,
        RtTransitTimeCycleDetailAllReportComponent,
        RtTransitTimeCycleSummaryReportComponent,
        RtTransitTimeCycleSummaryAllReportComponent,
        RtTransitTimeDayOfWeekComponent,
        RtTransitTimeDestinationStateComponent,
        RtTransitTimeRsDetailComponent,
        RtTransitTimeRsSummaryComponent,
        RtTransitTimeCtaBusinessComponent,
        RtTransitTimeCtaFleetComponent,
        RtTransitTimeCtaFleetBusinessComponent,
        TransitTimeTripCycleSummaryComponent,
        TransitTimeTripCycleDetailComponent,
        RtCompletedShipmentsAllComponent,
        RtCompletedShipmentsNoDiversionsComponent,
        RtOutboundByCustomerReportComponent,
        RtOutboundByCustomerCsrReportComponent,
        RtOutboundByCustomerProductReportComponent,
        RtOutboundByCustomerProductVolumeReportComponent,
        RtOutboundByFleetReportComponent,
        RtConsigneeCarRetDetailComponent,
        RtConsigneeCarRetSummaryComponent,
        RtDayInToOriginReportComponent,
        RtConsigneeCarRetSummaryComponent,
        RtDayOutToConsigneeReportComponent,
        EtaPerformanceRouteComponent,
        EtaPerformanceShipmentComponent,
        OriginPerformanceComponent,
        BadOrderHistoryComponent,
        CalculateTotalDays,
        CalculateVariance,
        CalculateCLMAge,
        CsSummaryByFleetBusinessGroupComponent,
        GetCountByStatus,
        GetTotalCount,
        CsSummaryByFleetCommClassComponent,
        CsSummaryCurrentDestinationComponent,
        CsSummaryCurrentOriginComponent,
        CsSummaryHistoryComponent,
        CsSummaryOriginHistoryComponent,
        DeliveryPerfConsigneeComponent,
        DeliveryPerfFleetBusinessGrpComponent,
        CarWithNoOutageComponent,
        OffLeaseReportComponent,
        OperationalMetricsComponent,
        TripCommentsReportComponent,
    ],
})
export class RailtracReportsModule {}
