import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { faTimes, faUndoAlt } from '@fortawesome/pro-regular-svg-icons';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtBusinessGroup, RtClm, RtFleetName, RtRouteCode, RtWhoPays } from '@bds/railtrac-models';
import { RtCustomerFact } from '@bds/reference-models';
import { RtDiversionFormModel } from '../models/rt-diversion-form.model';
import { RtDiversionDetail } from '../models/rt-diversion-detail.model';
import { RtDiversionAdapterService } from '../services/rt-diversion-adapter.service';
import { RtDiversionFormService } from '../services/rt-diversion-form.service';
import { CustomValidators } from '../services/rt-diversion.validators';
import { CLMSelectDialogModel } from '../../railtrac-clm/models/clm-select-dialog.model';
import { MatDialog } from '@angular/material/dialog';
import { CLMSelectDialogComponent } from '../../railtrac-clm/clm-select-dialog/clm-select-dialog.component';
import { RailtracTripService } from '@bds/reference-data-access';
import { firstValueFrom, map, Observable, switchMap, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
    BdsSplcErpcService,
    BdsCustomerService,
    RtCustomerTypeDefaultService,
    RtRouteService,
} from '@bds/data-access';
import { DiversionType } from '../../enum/diversionType.enum';

@Component({
    selector: 'rt-diversion-details',
    templateUrl: './rt-diversion-details.component.html',
    styleUrls: ['./rt-diversion-details.component.scss'],
})
export class RtDiversionDetailsComponent implements OnChanges, OnInit {
    activeFilter: string[] = ['activeStatus', '<>', 'N'];
    currentDate: Date = new Date();
    custFilter: any[] = null;
    defaultCustomerType: string = '';
    diversionForm: UntypedFormGroup;
    divertType: string;
    filteredDiversionTypes: { key: string; value: string }[] = [];
    iconRemove = faTimes;
    iconUndo = faUndoAlt;
    lastShipDate: Date;
    popupDim = { minWidth: '250px' };
    routeSearchExpr: string[] = ['routeCode', 'routeDescription'];
    routeSource: DataSource;
    splcDisplayExpr: string[] = ['erpcCity', 'erpcState', 'splc'];
    splcSource: DataSource;
    filter: string[] = ['isActive', '=', 'Y'];

    @Input() tripIds: number[] = [];
    @Input() businessGroups: RtBusinessGroup[];
    @Input() diversion: RtDiversionFormModel;
    @Input() diversionTypes: { key: string; value: string }[];
    @Input() fleetNames: RtFleetName[];
    @Input() isSaving = false;
    @Input() routeStore: ODataStore;
    @Input() splcStore: ODataStore;
    @Input() whoPays: RtWhoPays[];
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<RtDiversionFormModel>();

    routeDisplay = function (data: RtRouteCode) {
        return data ? `${data.routeCode}` : '';
    };

    clmHistory = {
        type: 'default',
        icon: 'plus',
        stylingMode: 'text',
        onClick: (): void => this.selectCLM(),
    };

    constructor(
        private diversionAdapter: RtDiversionAdapterService,
        private diversionFormService: RtDiversionFormService,
        private tripService: RailtracTripService,
        private routeService: RtRouteService,
        private customerTypeDefaultService: RtCustomerTypeDefaultService,
        private splcService: BdsSplcErpcService,
        public customerApiService: BdsCustomerService,
        private formErrorService: FormErrorHandlerService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        this.diversionForm = diversionFormService.createForm();
        this.currentDate.setHours(0, 0, 0, 0);
    }

    ngOnInit() {
        // TODO: Only run if this is empty
        this.setUpSplcOptions();
        this.setUpRouteOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.diversionTypes && this.diversionTypes) {
            if (this.diversion) {
                this.filterDivertOptions(this.diversion.roads[0].diversions[0]);
            } else {
                this.filteredDiversionTypes = this.diversionTypes;
            }
        }

        if (changes.diversion && this.diversion) {
            this.setupDiversionFormArray();
        }
    }

    private setupDiversionFormArray() {
        this.resetForm();
        this.diversionForm.patchValue(this.diversion);

        if (this.diversionTypes && this.diversionTypes.length) {
            this.filterDivertOptions(this.diversion.roads[0].diversions[0]);
        }

        const roadControl: UntypedFormArray = this.diversionForm.controls.roads as UntypedFormArray;
        this.diversion.roads.forEach((r) => {
            const roadForm: UntypedFormGroup = this.diversionFormService.createRoad();
            roadForm.patchValue(r);

            const diversionControl: UntypedFormArray = roadForm.controls
                .diversions as UntypedFormArray;
            r.diversions.forEach((t) => {
                const diversionForm: UntypedFormGroup = this.diversionFormService.createDiversion();
                diversionForm.patchValue(t);
                diversionControl.push(diversionForm);
            });

            roadControl.push(roadForm);
        });

        this.calculateMinDivertDate();
    }

    calculateMinDivertDate(): void {
        const emptyDiversion = {} as RtDiversionDetail;
        const data = this.diversionForm.value as RtDiversionFormModel;

        this.lastShipDate = this.diversionAdapter
            .flattenDiversions(data.roads)
            .reduce(
                (prev, current) => (prev.shipDateTime > current.shipDateTime ? prev : current),
                emptyDiversion,
            ).shipDateTime;

        const divertControl = this.getHeader().get('divertDateTime');

        if (
            data.header.divertDateTime &&
            data.header.divertDateTime < new Date(this.lastShipDate)
        ) {
            divertControl.setValue(null);
        }

        divertControl.setValidators([
            Validators.required,
            CustomValidators.dateMinimum(this.lastShipDate),
        ]);
        divertControl.updateValueAndValidity();
    }

    filterDivertOptions(trip: RtDiversionDetail): void {
        this.getTripInitiateStatus(trip).subscribe({
            next: (tripInitiateStatus: string) => {
                this.setDiversionTypes(trip, tripInitiateStatus);
            },
            error: (err) => {
                console.error('Error in filterDivertOptions():', err); // Handle error
            },
        });
    }

    setDiversionTypes(trip: RtDiversionDetail, tripInitiateStatus: string) {
        this.diversion.header.divertType =
            trip.carStatus === '1'
                ? DiversionType.DESTINATION_DIVERSION
                : DiversionType.RETURN_DIVERSION;
        this.divertType = this.diversion.header.divertType;
        this.filteredDiversionTypes = this.diversionTypes;
        if (trip.carStatus === '1' && trip.clmLe === 'L') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) =>
                    d.key === DiversionType.DESTINATION_DIVERSION ||
                    d.key === DiversionType.STORAGE_DIVERSION,
            );
        } else if (tripInitiateStatus === '3' && trip.carStatus === '3' && trip.clmLe === 'L') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) =>
                    d.key === DiversionType.DESTINATION_DIVERSION ||
                    d.key === DiversionType.STORAGE_DIVERSION,
            );
        } else if (trip.carStatus === '3' && trip.clmLe === 'L') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) =>
                    d.key === DiversionType.RETURN_DIVERSION ||
                    d.key === DiversionType.STORAGE_DIVERSION,
            );
        } else if (trip.carStatus === '1' && trip.clmLe === 'E') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) => d.key !== DiversionType.RETURN_DIVERSION,
            );
            this.diversion.header.divertType = 'D';
        } else if (tripInitiateStatus === '3' && trip.carStatus === '3' && trip.clmLe === 'E') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) => d.key !== DiversionType.RETURN_DIVERSION,
            );
        } else if (trip.carStatus === '3' && trip.clmLe === 'E') {
            this.filteredDiversionTypes = this.diversionTypes.filter(
                (d) => d.key !== DiversionType.DESTINATION_DIVERSION,
            );
        }

        // Set the dropdown
        this.setControlsPreDivertTypeChange(null, this.divertType);
        this.getHeader().get('divertType').setValue(this.divertType);
        this.setControlsPostDivertTypeChange(null, this.divertType);
    }

    /** Method to get the trip initiate status.Get the begin status of the customer type based on the customer
     * of the trip.
     *  */
    getTripInitiateStatus(trip: RtDiversionDetail): Observable<string> {
        //Get Trip customer
        return this.customerApiService.getByCustNo(trip.prevCustNo).pipe(
            switchMap((customer) => {
                //Get customer type for the customer
                return this.customerTypeDefaultService
                    .getByCustTypeAndLE(customer?.customerType, trip.clmLe)
                    .pipe(
                        map((customerType) => {
                            return customerType ? customerType.beginStatus : '';
                        }),
                    );
            }),
        );
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        if (formItem.hasError('date-minimum')) {
            return `Must be >= ${formItem.errors['date-minimum']['date-minimum']}`;
        } else {
            return this.formErrorService.getFormError(formItem);
        }
    }

    getHeader(): UntypedFormGroup {
        return this.diversionForm.get('header') as UntypedFormGroup;
    }

    getRoadByIndex(i: number): UntypedFormGroup {
        return this.getRoads().controls[i] as UntypedFormGroup;
    }

    getRoads(): UntypedFormArray {
        return this.diversionForm.get('roads') as UntypedFormArray;
    }

    getDiversionByIndex(iRoad: number, iDiversion: number): UntypedFormGroup {
        const diversions: UntypedFormArray = this.getDiversions(iRoad) as UntypedFormArray;
        return diversions.controls[iDiversion] as UntypedFormGroup;
    }

    getDiversions(i: number): UntypedFormArray {
        const roadList: UntypedFormArray = this.diversionForm.get('roads') as UntypedFormArray;
        return roadList.controls[i].get('diversions') as UntypedFormArray;
    }

    onCancel(): void {
        this.cancel.emit();
    }

    onCalendarOpened(event): void {
        const divertDate = this.getHeader().get('divertDateTime').value;

        if (!divertDate) {
            // Set date to midnight of current day
            setTimeout(() => {
                event.component._strategy._timeView.option('value', this.currentDate);
                event.component._strategy._widget.option('value', this.currentDate);
            });
        }
    }

    onCustomerChanged(event: RtCustomerFact): void {
        const headerControls: UntypedFormGroup = this.getHeader();
        if (event) {
            headerControls.get('newCustNo').setValue(event.customerNo);
            headerControls.get('newCustName').setValue(event.customerName);
            headerControls.get('newCustCity').setValue(event.customerCity);
            headerControls.get('newCustState').setValue(event.customerState);
            void this.setReturnLocationByCustomerType(event);
        } else {
            headerControls.get('newCustNo').setValue(null);
            headerControls.get('newCustName').setValue(null);
            headerControls.get('newCustCity').setValue(null);
            headerControls.get('newCustState').setValue(null);
        }
    }

    async setReturnLocationByCustomerType(cust: RtCustomerFact): Promise<void> {
        if (!cust?.customerType) {
            return;
        }

        const headerControls: UntypedFormGroup = this.getHeader();
        const result = await firstValueFrom(
            this.customerTypeDefaultService.getCustomerTypeDefaults(cust.customerType),
        );

        if (result && result.length > 0) {
            const beginStatus = result.find(
                (x) => x.loadedEmptyIndicator === headerControls.get('le').value,
            ).beginStatus;
            headerControls.get('newReturnSplc').enable();
            headerControls.get('newReturnSplc').reset();

            if (beginStatus === '3') {
                const splc = await firstValueFrom(this.splcService.getBySplc(cust.customerSplc));
                if (splc) {
                    headerControls.get('newReturnSplc').setValue(splc.splc);
                    headerControls.get('newReturnSplc').disable();
                }
            }
        }
    }

    onDivertDatePasted(event): void {
        const pastedItem = event.event.originalEvent.clipboardData.items[0];
        pastedItem.getAsString(function (data) {
            event.component.option('value', new Date(data));
        });
    }

    onDivertTypeChange(event): void {
        this.getHeader().get('newCustNo').markAsDirty();

        if (event && event.value) {
            const oldDivertType = this.divertType;
            // First remove all validators and values that cannot exist
            this.setControlsPreDivertTypeChange(oldDivertType, event.value);
            // Now change divertType and reset validators, values, and filters as needed
            this.divertType = event.value;
            this.setControlsPostDivertTypeChange(oldDivertType, event.value);
        }
    }

    onRemoveClick(diversion: UntypedFormGroup, roadId): void {
        const newDivertValue = !diversion.controls['divert'].value;
        diversion.controls['divert'].setValue(newDivertValue);

        if (!newDivertValue) {
            (diversion.controls['businessGroup'] as UntypedFormControl).disable();
            (diversion.controls['fleetId'] as UntypedFormControl).disable();
            (diversion.controls['routeDescription'] as UntypedFormControl).disable();

            if (this.divertType === 'S' || this.divertType === 'Q') {
                (diversion.controls['returnSplc'] as UntypedFormControl).disable();
            } else if (this.divertType === 'L') {
                (diversion.controls['orderNo'] as UntypedFormControl).disable();
                (diversion.controls['bolNo'] as UntypedFormControl).disable();
                (diversion.controls['routeCode'] as UntypedFormControl).disable();
            }
        } else {
            (diversion.controls['businessGroup'] as UntypedFormControl).enable();
            (diversion.controls['fleetId'] as UntypedFormControl).enable();
            (diversion.controls['routeDescription'] as UntypedFormControl).enable();

            if (this.divertType === 'S' || this.divertType === 'Q') {
                (diversion.controls['returnSplc'] as UntypedFormControl).enable();
            } else if (this.divertType === 'L') {
                (diversion.controls['orderNo'] as UntypedFormControl).enable();
                (diversion.controls['bolNo'] as UntypedFormControl).enable();
                (diversion.controls['routeCode'] as UntypedFormControl).enable();
            }
        }

        this.calculateMinDivertDate();
    }

    async setRouteDescriptionByRouteCode(
        code: string,
        control: AbstractControl<any, any>,
    ): Promise<void> {
        const route = await firstValueFrom(this.routeService.read(code));
        if (route) {
            control.setValue(route.routeDscr);
        }
    }

    onRoadChanged(event, road, type: string): void {
        const newValue: string = event.target.value;
        const diversionControls: UntypedFormGroup[] = road.controls.diversions.controls;

        diversionControls.forEach((c) =>
            (c.controls[type] as UntypedFormControl).setValue(newValue),
        );
    }

    onRoadDropdownChanged(event, road, type: string): void {
        const newValue: string = event.selectedItem[type];
        const diversionControls: UntypedFormGroup[] = road.controls.diversions.controls;

        diversionControls.forEach((c) =>
            (c.controls[type] as UntypedFormControl).setValue(newValue),
        );
    }

    onRouteChanged(event, type: string, ctrl): void {
        if (event) {
            const routeDescription = event.routeDescription || event.routeDscr;
            if (type === 'road' && ctrl) {
                (ctrl.controls['routeDescription'] as UntypedFormControl).setValue(
                    routeDescription,
                );
                const diversionControls: UntypedFormGroup[] = ctrl.controls.diversions.controls;

                diversionControls.forEach((c) => {
                    (c.controls['routeDescription'] as UntypedFormControl).setValue(
                        routeDescription,
                    );
                    const rc = c.controls['routeCode'] as UntypedFormControl;
                    rc.setValue(event.routeCode);
                    rc.markAsTouched();
                });
            } else if (type === 'diversion' && ctrl) {
                (ctrl.controls['routeDescription'] as UntypedFormControl).setValue(
                    routeDescription,
                );
                const headerControls: UntypedFormGroup = this.getHeader();
                const rc = ctrl.controls['routeCode'] as UntypedFormControl;
                rc.setValue(event.routeCode);
                headerControls
                    .get('newReturnSplc')
                    .setValue(event.destinationSplc ?? event.destSplc);
                rc.markAsTouched();
            }
        }
    }

    onSplcChanged(event, type: string, ctrl = null): void {
        if (event && event.selectedItem) {
            if (type === 'header') {
                const headerControls: UntypedFormGroup = this.getHeader();

                headerControls.get('newReturnCity').setValue(event.selectedItem.erpcCity);
                headerControls.get('newReturnState').setValue(event.selectedItem.erpcState);
            } else if (type === 'road' && ctrl) {
                const diversionControls: UntypedFormGroup[] = ctrl.controls.diversions.controls;

                diversionControls.forEach((c) => {
                    const splc = c.controls['returnSplc'] as UntypedFormControl;
                    splc.setValue(event.selectedItem.splc);
                    splc.markAsTouched();
                });
            }
        }
    }

    onSubmit(): void {
        const diversion: RtDiversionFormModel = { ...this.diversionForm.value };
        this.save.emit(diversion);
    }

    resetForm(): void {
        this.getRoads().clear();
        this.diversionForm.reset();
    }

    setUpRouteOptions(): void {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.routeSource = new DataSource({
            store: this.routeStore,
            paginate: true,
            pageSize: 10,
            searchExpr: this.routeSearchExpr,
            select: ['routeCode', 'routeDescription'],
            sort: ['routeCode', 'routeDescription'],
            key: 'routeCode',
        });
    }

    setUpSplcOptions(): void {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.splcSource = new DataSource({
            store: this.splcStore,
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['erpcCity', 'erpcState', 'splc'],
            sort: ['erpcCity', 'erpcState'],
            key: 'splc',
        });
    }

    setControlsPostDivertTypeChange(oldDivertValue: string, newDivertValue: string): void {
        const headerControls: UntypedFormGroup = this.getHeader();

        if ((oldDivertValue === 'O' || !oldDivertValue) && newDivertValue !== 'O') {
            // Make sure anything in the Customer inputs is cleared
            headerControls?.get('newCustCity').setValue(null);
            headerControls?.get('newCustState').setValue(null);
            // Now make this a required field
            headerControls?.get('newCustNo').setValidators([Validators.required]);
            headerControls?.get('newCustNo').updateValueAndValidity();
        } else if (newDivertValue === 'O') {
            headerControls?.get('newReturnSplc').setValidators([Validators.required]);
            headerControls?.get('newReturnSplc').updateValueAndValidity();

            const custNo =
                this.diversion?.roads?.length > 0
                    ? this.diversion.roads[0].diversions[0].prevCustNo
                    : null;

            headerControls?.get('newCustNo').setValue(custNo);

            void this.setCustomerCityState(custNo, headerControls);
        }

        if (newDivertValue === 'S' || newDivertValue === 'Q' || newDivertValue === 'O') {
            this.defaultCustomerType = newDivertValue;
            if (newDivertValue === 'S' || newDivertValue === 'Q') {
                this.custFilter = [
                    [['customerType', '=', newDivertValue], 'or', ['customerType', '=', 'G']],
                    'and',
                    this.activeFilter,
                ];
            }
            const roads = (this.diversionForm.get('roads') as UntypedFormArray)
                .controls as UntypedFormGroup[];
            roads.forEach((g) => {
                const roadSplc = g.get('returnSplc');
                if (roadSplc) {
                    const diversions = (g.get('diversions') as UntypedFormArray)
                        .controls as UntypedFormGroup[];
                    diversions.forEach((d) => {
                        const routeDesc = d.get('routeDescription');
                        const routeCode = d.get('routeCode');
                        if ((newDivertValue === 'S' || newDivertValue === 'Q') && routeDesc) {
                            routeCode.setValue(
                                newDivertValue === 'S' ? 'DIVERT TO SHOP' : 'DIVERT TO STORAGE',
                            );
                            routeDesc.setValue(
                                newDivertValue === 'S'
                                    ? 'DIVERT CAR TO SHOP'
                                    : 'DIVERT CAR TO STORAGE',
                            );
                        } else {
                            routeCode.setValue(
                                this.diversion?.roads?.length > 0
                                    ? this.diversion.roads[0].diversions[0].prevRouteCode
                                    : null,
                            );

                            void this.setRouteDescriptionByRouteCode(
                                this.diversion?.roads?.length > 0
                                    ? this.diversion.roads[0].diversions[0].prevRouteCode
                                    : null,
                                routeDesc,
                            );
                        }

                        const splc = d.get('returnSplc');
                        if (splc && splc.value) {
                            splc.setValidators([Validators.required]);
                            splc.updateValueAndValidity();
                        }
                    });
                }
            });
        } else {
            this.defaultCustomerType = null;
            // Only take those not specifially listed as inactive
            this.custFilter = this.activeFilter;
        }
    }

    private async setCustomerCityState(custNo: string, headerControls: UntypedFormGroup) {
        const cust = await firstValueFrom(this.customerApiService.getByCustNo(custNo));

        if (cust) {
            headerControls?.get('newCustCity').setValue(cust.customerCity);
            headerControls?.get('newCustState').setValue(cust.customerState);
        }
    }

    setControlsPreDivertTypeChange(oldDivertValue, newDivertValue): void {
        const headerControls: UntypedFormGroup = this.getHeader();
        const returnSplcCtrl = headerControls.get('newReturnSplc');
        const custNoCtrl = headerControls.get('newCustNo');

        if (oldDivertValue !== 'O' && newDivertValue !== 'O' && custNoCtrl) {
            custNoCtrl.setValue(null);
            headerControls.get('newCustCity').setValue(null);
            headerControls.get('newCustState').setValue(null);
        } else if (newDivertValue !== 'O' && returnSplcCtrl) {
            returnSplcCtrl.clearValidators();
            returnSplcCtrl.updateValueAndValidity();
            returnSplcCtrl.setValue(null);
        } else if (newDivertValue === 'O' && custNoCtrl) {
            custNoCtrl.clearValidators();
            custNoCtrl.updateValueAndValidity();
            custNoCtrl.setValue(null);
        }

        const roads = (this.diversionForm.get('roads') as UntypedFormArray)
            .controls as UntypedFormGroup[];
        roads.forEach((g) => {
            const roadSplc = g.get('returnSplc');
            if (roadSplc) {
                roadSplc.setValue(null);
                const diversions = (g.get('diversions') as UntypedFormArray)
                    .controls as UntypedFormGroup[];
                diversions.forEach((d) => {
                    const routeDesc = d.get('routeDescription');
                    if (routeDesc) {
                        routeDesc.setValue(null);
                    }
                    const splc = d.get('returnSplc');
                    if (splc) {
                        splc.clearValidators();
                        splc.updateValueAndValidity();
                        splc.setValue(null);
                    }
                });
            }
        });
    }

    selectCLM(): void {
        if (this.tripIds && this.tripIds?.length > 0) {
            this.tripService
                .read(this.tripIds[0])
                .pipe(take(1))
                .subscribe({
                    next: (trip) => {
                        const data = new CLMSelectDialogModel();
                        data.actionText = 'Add to Diversion';
                        data.title = 'CLM History';
                        data.trip = trip;

                        const dialogRef = this.dialog.open(CLMSelectDialogComponent, {
                            data: data,
                        });
                        dialogRef.afterClosed().subscribe((s: RtClm) => {
                            if (s) {
                                this.diversion.roads.forEach((x) => {
                                    const diversions: RtDiversionDetail[] = x.diversions.map(
                                        (y): RtDiversionDetail => ({
                                            ...y,
                                            clmDateTime: s.clmDateTime,
                                            clmLocCity: s.locationCity,
                                            clmLocState: s.locationState,
                                            clmLocSplc: s.locationSplc,
                                            clmSightCode: s.sightCode,
                                            clmRoad: s.road,
                                            routeCode: s.routeCode,
                                        }),
                                    );

                                    x.diversions = diversions;
                                });

                                const clmDate =
                                    s.sightCode === 'Q'
                                        ? new Date(
                                              moment(s.clmDateTime)
                                                  .add(1, 'minutes')
                                                  .utc()
                                                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                                          )
                                        : new Date(
                                              moment(s.clmDateTime)
                                                  .utc()
                                                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                                          );

                                this.setupDiversionFormArray();
                                this.getHeader().get('divertDateTime').setValue(clmDate);
                            }
                        });
                    },
                    error: () => {
                        this._snackbar.open('Trip retrieval Failed', 'Error', {
                            duration: 3000,
                        });
                    },
                });
        }
    }

    formatDate(date: Date): string {
        return date ? moment(date).utc().format('MM/DD/YYYY HH:mm:ss') : '';
    }
}
