<div *ngIf="!!trip">
    <div class="sticky-top mat-elevation-z6">
        <mat-toolbar class="trip-header-bar bds-toolbar-blue">
            <mat-toolbar-row>
                <span>
                    <fa-icon [icon]="icons.train" [fixedWidth]="true" size="lg"></fa-icon>
                    {{ trip.carInit }} {{ trip.carNo }}
                    <span class="small">{{
                        trip.shipDatetime | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC'
                    }}</span>
                    <span *ngIf="isDirty">*</span>
                    <span *ngIf="isSaving">
                        <small>
                            (saving...)
                            <fa-icon [icon]="icons.spinner" [spin]="true"></fa-icon>
                        </small>
                    </span>
                    <a
                        mat-icon-button
                        title="Open Equipment in a new Tab"
                        [href]="equipmentAppPathProvider.EquipmentById(equipment.ormId) | async"
                        target="_blank"
                    >
                        <fa-icon [icon]="icons.popout"></fa-icon>
                    </a>
                </span>
                <span class="fill-remaining-space"></span>
                <span>
                    <button
                        mat-stroked-button
                        (click)="saveAndCreateNew()"
                        [disabled]="isSaving || !isTripValid"
                        *ngIf="isNew"
                    >
                        <fa-icon [icon]="icons.saveAndCreateNew"></fa-icon> Save and Create New Trip
                    </button>
                    <button
                        mat-stroked-button
                        (click)="saveChanges()"
                        [disabled]="isSaving || !isTripValid"
                    >
                        <fa-icon [icon]="icons.save"></fa-icon> Save Trip
                    </button>
                    <a
                        mat-icon-button
                        target="_blank"
                        [routerLink]="['/trip', trip.ormId]"
                        title="Open Trip in New Tab"
                    >
                        <fa-icon [icon]="icons.popout"></fa-icon>
                    </a>
                    <rt-trip-more-options
                        [trip]="trip"
                        [clms]="clms"
                        (resolve)="onResolveTripOptions($event)"
                    ></rt-trip-more-options>
                    <button mat-icon-button (click)="tripSidenav.toggle()">
                        <fa-icon [icon]="icons.menu"></fa-icon>
                    </button>
                    <div *ngIf="!isTripValid" style="text-align: left; margin-top: -10px">
                        <small class="fs-6 text-danger">Required fields are missing</small>
                    </div>
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="container-fluid jump-nav">
            <mat-chip-listbox>
                <mat-chip-option
                    bdsScrollSpyLink="bdsTripGrid"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'bdsTripGrid'"
                    (click)="setCurrentSection('bdsTripGrid')"
                >
                    <span>Shipment Grid</span>
                </mat-chip-option>
                <mat-chip-option
                    bdsScrollSpyLink="tripdetailsanchor"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="
                        (scrollSpyService.currentSection | async) === 'tripdetailsanchor'
                    "
                    (click)="setCurrentSection('tripdetailsanchor')"
                >
                    <span>Shipment Details</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="trip.tcmEquipmentOrmId"
                    bdsScrollSpyLink="tripproductshippedanchor"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="
                        (scrollSpyService.currentSection | async) === 'tripproductshippedanchor'
                    "
                    (click)="setCurrentSection('tripproductshippedanchor')"
                >
                    <span>Products Shipped</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="!isNew"
                    bdsScrollSpyLink="tripdiversionsanchor"
                    (click)="tripdiversionspanel.open(); setCurrentSection('tripdiversionsanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="
                        (scrollSpyService.currentSection | async) === 'tripdiversionsanchor'
                    "
                >
                    <span>Diversions</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="trip.tcmEquipmentOrmId"
                    bdsScrollSpyLink="tripclmanchor"
                    (click)="tripclmpanel.open(); setCurrentSection('tripclmanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'tripclmanchor'"
                >
                    <span>CLM History</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="trip.routeCode"
                    bdsScrollSpyLink="triprouteanchor"
                    (click)="triproutepanel.open(); setCurrentSection('triprouteanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'triprouteanchor'"
                >
                    <span>Route</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="trip.customerNo"
                    bdsScrollSpyLink="tripcustanchor"
                    (click)="tripcustpanel.open(); setCurrentSection('tripcustanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'tripcustanchor'"
                >
                    <span>Customer</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="!isNew"
                    bdsScrollSpyLink="tripcommentsanchor"
                    (click)="tripcommentspanel.open(); setCurrentSection('tripcommentsanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="
                        (scrollSpyService.currentSection | async) === 'tripcommentsanchor'
                    "
                >
                    <span>Comments</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="!isNew"
                    bdsScrollSpyLink="tripequipanchor"
                    (click)="tripequippanel.open(); setCurrentSection('tripequipanchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'tripequipanchor'"
                >
                    <span>Reporting Categories</span>
                </mat-chip-option>
                <mat-chip-option
                    *ngIf="!isNew"
                    bdsScrollSpyLink="hotListAnchor"
                    (click)="hotListAnchor.open(); setCurrentSection('hotListAnchor')"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'hotListAnchor'"
                >
                    <span>Hot Lists</span>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
    <mat-sidenav-container>
        <mat-sidenav mode="side" position="end" class="mat-elevation-z4" #tripSidenav>
            Nothing to see here
        </mat-sidenav>
        <div
            bdsScrollSpySection="tripdetailsanchor"
            [scrollMargin]="116"
            #tripdetailsanchor
            class="mt-1"
        >
            <div class="div-wrapper">
                <div class="error-message">{{ errorMessage }}</div>
            </div>
            <rt-trip-details
                #tripDetails
                [trip]="trip"
                [tripRefFieldDefs]="tripRefFieldDefs$ | async"
                [highlightDirty]="true"
                (equipmentChange)="onEquipmentChange($event)"
                (formValidChange)="onFormValidChange($event)"
                (tripValuesChange)="onTripValuesChange($event)"
                (navigateClmSection)="setCurrentSection('tripclmanchor'); tripclmpanel.open()"
                (notifyToUpdateTrip)="saveChanges()"
                [allowPopout]="allowPopout"
                [allowChangeKey]="isNew"
            >
            </rt-trip-details>
        </div>
        <div
            *ngIf="trip.tcmEquipmentOrmId"
            class="container"
            bdsScrollSpySection="tripproductshippedanchor"
            [scrollMargin]="108"
            #tripproductshippedanchor
        >
            <!-- <mat-toolbar>
                <mat-toolbar-row class="row"> -->
            <div class="row">
                <div class="col-10 d-flex flex-row">
                    <span>Products Shipped</span>
                </div>
                <div class="col-2 text-right">
                    <button mat-flat-button (click)="onAddProductClick()">
                        <fa-duotone-icon
                            [icon]="iconAdd"
                            primaryColor="forestgreen"
                            secondaryColor="#97bdd3"
                        ></fa-duotone-icon>
                        Add Product
                    </button>
                    <!-- <button mat-stroked-button (click)="onAddProductClick()">
                            Add Product
                        </button> -->
                </div>
            </div>
            <!-- </mat-toolbar-row>
            </mat-toolbar> -->

            <ng-container *ngFor="let compart of commoditiesShipped; let idx = index">
                <mat-card class="mb-3">
                    <div class="row">
                        <div class="col-10">
                            <trip-commodity-shipped [commodityShipped]="compart">
                            </trip-commodity-shipped>
                            <!-- <rt-trip-commodity-shipped
                                [commodityShipped]="compart"
                                (commodityShippedChange)="commShipChange($event, idx)"
                                [highlightDirty]="true"
                            >
                            </rt-trip-commodity-shipped> -->
                        </div>
                        <div class="col-2">
                            <button mat-icon-button (click)="onEditProductClick(compart)">
                                <fa-duotone-icon
                                    [icon]="iconEdit"
                                    primaryColor="#005c92"
                                    secondaryColor="#005c92"
                                    [fixedWidth]="true"
                                ></fa-duotone-icon>
                            </button>
                            <button mat-icon-button (click)="onDeleteProductClick(compart.ormId)">
                                <fa-duotone-icon
                                    [icon]="iconDelete"
                                    primaryColor="red"
                                    secondaryColor="red"
                                    [fixedWidth]="true"
                                ></fa-duotone-icon>
                            </button>
                        </div>
                    </div>
                </mat-card>
            </ng-container>
            <!-- <h5 *ngIf="unassignedCommodities.length > 0">
                Commodities Assigned to Invalid Compartments
            </h5>
            <div *ngFor="let compart of unassignedCommodities">
                <rt-trip-commodity-shipped [commodityShipped]="compart" [highlightDirty]="true">
                </rt-trip-commodity-shipped>
            </div> -->
        </div>
        <div class="container-fluid">
            <div
                *ngIf="!isNew"
                class="mb-3"
                bdsScrollSpySection="tripdiversionsanchor"
                [scrollMargin]="116"
                #tripdiversionsanchor
            >
                <mat-expansion-panel #tripdiversionspanel id="tripdiversionspanel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.diversion" [fixedWidth]="true"></fa-icon>
                            Diversions
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-diversion
                            #diversionComponent
                            *ngIf="!isNew"
                            [tripId]="trip.ormId"
                            (diversionCreated)="onDiversionCreated($event)"
                        >
                        </rt-diversion>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="trip.tcmEquipmentOrmId"
                class="mb-3"
                bdsScrollSpySection="tripclmanchor"
                [scrollMargin]="116"
                #tripclmanchor
            >
                <mat-expansion-panel #tripclmpanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.history" [fixedWidth]="true"></fa-icon>
                            Current CLM:
                            <ng-container *ngIf="currentClm">
                                {{
                                    currentClm.sightCode
                                        | lookup : (sightCodes | async) : 'id' : 'name'
                                }}
                                <small class="text-muted"
                                    >&nbsp;({{ currentClm.sightCode }})&nbsp;</small
                                >
                                - {{ currentClm.locationCity }}, {{ currentClm.locationState }} -
                                {{
                                    currentClm.clmDateTime | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC'
                                }}</ng-container
                            >
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-clm
                            *ngIf="!isNew"
                            [carId]="trip.carInit + ' ' + trip.carNo"
                            [customerNumber]="trip.customerNo"
                            [destinationCity]="trip.destinationCity"
                            [destinationState]="trip.destinationState"
                            [disabledFields]="disabledClmFields"
                            [originCity]="trip.returnCity"
                            [originState]="trip.returnState"
                            [tripId]="trip.ormId"
                            [shipmentDate]="trip.shipDatetime"
                            [loadEmpty]="trip.loadEmpty"
                            (onClmReapplied)="reloadClms()"
                        ></rt-clm>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="trip.routeCode"
                class="mb-3"
                bdsScrollSpySection="triprouteanchor"
                [scrollMargin]="116"
                #triprouteanchor
            >
                <mat-expansion-panel #triproutepanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.route" [fixedWidth]="true"></fa-icon>
                            Route
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <bds-route
                            [routeCode]="trip.routeCode"
                            [readOnly]="routeReadOnly"
                        ></bds-route>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="trip.customerNo"
                class="mb-3"
                bdsScrollSpySection="tripcustanchor"
                [scrollMargin]="116"
                #tripcustanchor
            >
                <mat-expansion-panel #tripcustpanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.customer" [fixedWidth]="true"></fa-icon>
                            Customer
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <bds-customer-display
                            [customerNumber]="trip.customerNo"
                            [editLink]="'/customers'"
                            [allowEdit]="true"
                        >
                        </bds-customer-display>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="!isNew"
                class="mb-3"
                bdsScrollSpySection="tripcommentsanchor"
                [scrollMargin]="116"
                #tripcommentsanchor
            >
                <mat-expansion-panel #tripcommentspanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.comments" [fixedWidth]="true"></fa-icon>
                            Comments
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-comments-for-trip
                            [tripId]="trip.ormId"
                            [carInit]="trip.carInit"
                            [carNumber]="trip.carNo"
                            [shipDatetime]="originalShipmentDate ?? trip?.shipDatetime"
                        >
                        </rt-comments-for-trip>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="!isNew"
                class="mb-3"
                bdsScrollSpySection="tripequipanchor"
                [scrollMargin]="116"
                #tripequipanchor
            >
                <mat-expansion-panel #tripequippanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.group" [fixedWidth]="true"></fa-icon>
                            Reporting Categories
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <bds-equipment-report-categories
                            [equipId]="trip.tcmEquipmentOrmId"
                            [shipmentDate]="trip.shipDatetime"
                            [tripCloseDate]="trip.tripCloseDateTime"
                            [equipInit]="trip.carInit"
                            [equipNumber]="trip.carNo"
                        >
                        </bds-equipment-report-categories>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                *ngIf="!isNew"
                class="mb-3"
                bdsScrollSpySection="hotListAnchor"
                [scrollMargin]="116"
            >
                <mat-expansion-panel #hotListAnchor>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="icons.list" [fixedWidth]="true"></fa-icon>
                            Hot Lists
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <bds-hot-list-details
                            [hotLists]="hotLists"
                            (saved)="reloadHotList().subscribe()"
                        >
                            <button mat-stroked-button (click)="confirmAddHotList()">
                                <fa-icon [icon]="icons.add"></fa-icon>
                                Add
                            </button>
                        </bds-hot-list-details>
                    </ng-template>
                </mat-expansion-panel>
            </div>
        </div>
    </mat-sidenav-container>
</div>
